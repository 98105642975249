import React from 'react';
import axios from 'axios';
import 'assets/scss/komsek.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';

import SEO from 'components/seo';
import Header from 'components/header';
import Footer from 'components/footer';

class News extends React.Component {
  state = { loading: true }

  componentDidMount() {
    axios.post('/cockpit/api/collections/get/news', {
      filter: { published: true },
      fields: { title: 1, content: 1, _modified: 1 },
      limit: 3,
      sort: { _modified: -1 }
    }).then(response => {
      this.setState({
        loading: false,
        data: response.data.entries,
      });
    }).catch(response => {
      this.setState({
        loading: false,
        data: null,
      });
    });
  }

  formatTime = (time) => {
    const d = new Date(time * 1000);
    return d.toLocaleString('cs-CZ');
  }

  render () {
    const { data, loading } = this.state;
    let container;

    if (!loading) {
      if (data === null) {
        container = (
          <div className="container grid-lg">
            <p>
              Při získávání dat nastala chyba&ensp;
              <FontAwesomeIcon icon={faBug} transform={{ rotate: 32 }} />.
              Zkuste to prosím později.
            </p>
          </div>
        );
      } else {
        container = (
          <div className="container grid-lg">
            <p>Litujeme &ndash; žádné novinky k zobrazení.</p>
          </div>
        );

        try {
          if (data.length > 2) {
            container = (
              <div className="container grid-xl">
                <div className="columns" style={{ justifyContent: 'center'}}>
                  {data.map(item => (
                    <div key={item._id} className="column col-4 col-md-6 col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <div className="card-title h5">
                            {item.title}
                          </div>
                          <div className="card-subtitle text-gray">
                            {this.formatTime(item._modified)}
                          </div>
                        </div>
                        <div className="card-body"
                          dangerouslySetInnerHTML={{ __html: item.content }} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          } else if (data.length === 2) {
            container = (
              <div className="container grid-lg">
                <div className="columns">
                  {data.map(item => (
                    <div key={item._id} className="column col-6 col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <div className="card-title h5">
                            {item.title}
                          </div>
                          <div className="card-subtitle text-gray">
                            {this.formatTime(item._modified)}
                          </div>
                        </div>
                        <div className="card-body"
                          dangerouslySetInnerHTML={{ __html: item.content }} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          } else if (data.length === 1) {
            container = (
              <div className="container grid-md">
                <div className="columns">
                  {data.map(item => (
                    <div key={item._id} className="column">
                      <div className="card">
                        <div className="card-header">
                          <div className="card-title h5">
                            {item.title}
                          </div>
                          <div className="card-subtitle text-gray">
                            {this.formatTime(item._modified)}
                          </div>
                        </div>
                        <div className="card-body"
                          dangerouslySetInnerHTML={{ __html: item.content }} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          }
        } catch {
          container = (
            <div className="container grid-lg">
              <p>Omlouváme se, ale při zpracování novinek nastala chyba :(</p>
            </div>
          );
        }
      }
    }

    return (
      <>
        <SEO title="Aktuality" />
        <Header />
        <article>
          <section>
            <div className="container grid-lg">
              <h1>Novinky</h1>
            </div>
            {loading
              ? <div className="column loading loading-lg"></div> : container}
          </section>
        </article>
        <Footer />
      </>
    );
  }
}

export default News;
